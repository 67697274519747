.container {
    padding: 10px;
    max-height: 350px;
    padding-top: 0;
}

.card {
    background-color: white;
    padding: 20px;
    padding-bottom: 50px;
}

.flexContainerPrimary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.flexContainerSecondary {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}

.heading {
    margin: 0;
    margin-right: 10px;
}

.marginBottom {
    margin-bottom: 20px;

}

@media (max-width: 444px) {
    .heading {
      font-size: 16px;
    }
  }
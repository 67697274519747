.specialistdiv{
display: grid ;
grid-template-columns: repeat(1, minmax(0, 1fr)); 
gap: 1.5rem; 
}

@media (min-width: 768px) { 
    .specialistdiv{
        display: grid ;
        gap: 1.5rem; 
  grid-template-columns: repeat(2, minmax(0, 1fr)); 
    }
 }
@media (min-width: 1024px) {
    .specialistdiv{ 
        display: grid ;
        gap: 1.5rem; 
  grid-template-columns: repeat(3, minmax(0, 1fr)); 
    }
 }

 .criteriondivgap{
    display: grid ;
grid-template-columns: repeat(2, minmax(0, 1fr)); 
column-gap: 1.5rem; 




 }
/* MobileMenu.module.css */
.mobileMenuOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
}
.aisms{
    display: flex;
    align-items: center;
    padding: 10px 0;
    text-decoration: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}
.mobileMenu {
    width: 250px;
    height: 100%;
    background-color: rgb(31, 154, 214);
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    animation: slideIn 0.3s forwards;
    position: relative;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.closeButton {
    position: absolute;
    top: 15px;
    right: 20px;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: white;
}

.mobileMenuLinks {
    display: flex;
    height: calc(100% - 70px);
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
}

.mobileMenuLinks a,
.mobileMenuLinks .logoutLink {
    display: flex;
    align-items: center;
    padding: 10px 0;
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}

.mobileMenuLinks a img,
.mobileMenuLinks .logoutLink img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
}

.logoutLink {
    color: white;
    font-weight: 400;
    letter-spacing: 1px;
    margin-left: 24px;
}

.navMobileList a {
    color: white;
}
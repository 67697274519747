.upload-btn {
    position: relative;
    overflow: hidden;
    display: inline-block;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f1f1f1;
    padding: 8px 12px;
    cursor: pointer;
    width: 40%;
    z-index: 5;
}


.send-message-button {
    background-color: #1f9ad6;
    color: white;
    margin-right: 10px;
    font-size: 12px;
}

.login-button {
    margin-top: 60px;
    height: 50px;
    width: 50%;
    border: 0px;
    border-radius: 8px;
    background-color: #1f9ad6;
    color: white;
    font-family: poppins;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
}
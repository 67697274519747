.sm-title {
    font-size: 13px;
    color: #000;
    font-family: inter;
    margin-bottom: 10px;
}

.public-info .card {
    padding: 10px;
    font-family: inter;
    background: #fff;
    border: none;
    box-shadow: 1px 5px 2px -2px #e5e5e5;
}

.public-info .card a {
    color: red;
    font-size: 13px;
    text-decoration: none;
}

.public-info .card .btn {
    color: #000;
    font-size: 13px;
    text-decoration: none;
    background-color: #56b3e0;
}

.public-info .card .sm-text {
    color: #757587;
    font-size: 13px;
    font-weight: 400;
}

.new-post .input-style {
    font-family: "inter";
    color: #757587;
    background: #fff;
    border: none;
    box-shadow: 1px 5px 2px -2px #e5e5e5;
    border-radius: 4px;
    padding: 10px 10px;
}

.prev-post .card {
    height: calc(100vh - 385px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 20px;
}

.prev-post .sm-text {
    cursor: pointer;
    margin-bottom: 20px;
}


.upload-btn .icn {
    position: absolute;
    width: 30px;
    height: 30px;
    object-fit: none;
    right: 0px;
    transform: translate(-50%, -50%);
    top: 50%;
    margin-right: 0;
    z-index: 1;
}

.upload-btn input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 5;
}

.upload-btn i {
    margin-right: 8px;
}

.textarea-count p {
    font-family: inter;
    font-size: 10px;
    letter-spacing: 0px;
    color: #757587;
}

.textarea-count div {
    padding-left: 10px;
}
.mainkeymetricdiv{
display: grid;
grid-template-columns: repeat(1, minmax(0, 1fr));
gap: 1rem; 
}
.tooltip-content:hover  {
    visibility: visible !important;
    opacity: 1 !important;
  }
  
@media (min-width: 768px) { 
    .mainkeymetricdiv{
        display: grid;

  grid-template-columns: repeat(2, minmax(0, 1fr)); 
  gap: 1rem; 

    }
 }
@media (min-width: 1024px) { 
    .mainkeymetricdiv{
        display: grid;

  grid-template-columns: repeat(4, minmax(0, 1fr)); 
  gap: 1rem; 

    }
 }

 .mainkeymetricdiv2{

 padding: 1.5rem; 
border-radius: 0.5rem; 
background-color: #ffffff; 
box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); 
 }
 .mainkeymetricdiv3{
 display: flex; 
justify-content: space-between; 
align-items: center; 
 }

 .mainkeymetricdiv4{
    display: flex; 
gap: 0.5rem; 
align-items: center; 

 }

 .starticon{
    width: 2rem; 
height: 2rem; 
color: #ffd700;
 }


 .tooltip-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-content {
    position: absolute;
    bottom: 130%;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    z-index: 50;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    color: #ffffff;
    white-space: pre-line;
    background-color: #111827;
    transition: opacity 0.3s ease-in-out;
    width: 200px;
  }
  
  .tooltip-arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #111827 transparent transparent transparent;
  }
  
  .info-icon:hover + .tooltip-content {
    visibility: visible;
    opacity: 1;
  }
  
:root {
  --primary: #1f9ad6;
  --secondary: rgba(86, 179, 224, 0.4);
  --accent: white;
  --color-text: black;
  --sm: 12px;
  --md: 16px;
  --lg: 24px;
  --xl: 36px;
}

/* App.css */
body {
  margin: 0;
 font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background-color: #1f9ad6;
}


.onFocus:focus {
  outline: none;
}

textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: #fff;
  min-height: calc(100vh - 295px);
  max-height: calc(100vh - 295px);
  overflow: auto;
  border: none;
  border-radius: 4px;
  box-shadow: 1px 5px 2px -2px #e5e5e5;
}

.main-section {
  overflow-y: auto;
  height: 100vh;
  margin-left: 250px;
}

.main-section ::-webkit-scrollbar {
  width: 3px;
}

.main-section ::-webkit-scrollbar-thumb {
  background: #1f9ad6;
}

/* End */
.scrollView {
  height: calc(100vh - 87px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.bm-thin {
  font-family: bm-thin;
}

.sm {
  font-size: 12px;
}

.md {
  font-size: 16px;
}

.lg {
  size: 24px;
}

.xl {
  font-size: 36px;
  line-height: 57px;
}

.accent {
  color: var(--accent);
}

p {
  font-size: var(--md);
  color: var(--accent);
  font-weight: 500;
  line-height: 20px;
}

.flex-row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login-text-field {
  width: 100%;
  background-color: white;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
}

/* Global Thin Scrollbar Styles */

/* For WebKit Browsers (Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-thumb {
  background-color: #1f9ad6;
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* For Firefox */


@media only screen and (max-width: 768px) {


  .main-section {
    margin-left: 0px;
  }

}

.logo {
  width: 180px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.go {
  background-color: #1f9ad6;
  border: 0;
  font-size: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 30px;
}
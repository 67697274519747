.chatBotOpenButton{
display: flex; 
position: fixed; 
right: 1.5rem; 
bottom: 1.5rem; 
justify-content: center; 
align-items: center; 
border-radius: 9999px; 
width: 3.5rem; 
height: 3.5rem; 
color: #ffffff; 
transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
transition-duration: 300ms; 
box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); 
background: #1f9ad6;


}



.chatBotOpenButton:hover{
 background: #1785bd;
 }

.specific-page-div {
    display: flex;
    flex-direction: row;
    background-color: #9ecfe8;
    padding: 0;
    align-items: center;
    border: 0;
}

.bottom-filter-string {
    color: black;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
}

.bottom-filter-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 8px; /* Add spacing between elements */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .bottom-filter-div {
        gap: 1px; /* Reduce spacing between elements */
    }

    .bottom-filter-string,
    p {
        font-size: 10px; /* Slightly smaller text */
    }

    select.form-control {
        font-size: 10px; /* Smaller font size for dropdown */
        width: 60px; /* Adjust width for smaller screens */
    }

    input.form-control {
        font-size: 10px; /* Smaller font size for input */
        width: 50px; /* Narrow input field */
    }

    button.btn {
        padding: 5px; /* Reduce padding on buttons */
        font-size: 10px; /* Smaller font size */
    }

    div[style*="width: 80px"] {
        width: 70px; /* Adjust the width of the "x of y" container */
    }
}

@media screen and (max-width: 480px) {
    .bottom-filter-div {
        justify-content: center; /* Center items on smaller screens */
    }

    .bottom-filter-string,
    p {
        font-size: 9px; /* Further reduce text size */
    }

    select.form-control {
        font-size: 9px;
        width: 50px; /* Narrower width for very small screens */
    }

    input.form-control {
        font-size: 9px;
        width: 45px;
    }

    button.btn {
        font-size: 9px;
        padding: 4px; /* Minimal padding for compact buttons */
    }

    div[style*="width: 80px"] {
        width: 60px; /* Further reduce width */
    }
}

/* Login.module.css */

.loginPage {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #d1e2ee;
}

.textSecondary {
    font-family: system-ui;
    font-weight: 400;
    font-size: 16px;
}

.paragraph {
    color: #7a838a;
    font-family: system-ui;
    font-weight: 400;
    font-size: 14px;
}

.textDecorationNone {
    color: rgb(2, 136, 209);
}

.btnPrimary {
    background-color: rgb(2, 136, 209);
    border-color: rgb(2, 136, 209);
}

.forgotPasswordLink {
    color: rgb(2, 136, 209);
}
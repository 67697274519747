.min-input {
    width: 120px;
    font-size: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.max-input {
    width: 120px;
    font-size: 12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
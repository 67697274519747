.tooltip-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-content {
    position: absolute;
    bottom: 130%;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    z-index: 50;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    color: #ffffff;
    white-space: pre-line;
    background-color: #111827;
    transition: opacity 0.3s ease-in-out;
    width: 160px;
  }
  
  .tooltip-arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #111827 transparent transparent transparent;
  }
  
  .info-icon:hover + .tooltip-content {
    width: 160px;
    visibility: visible;
    opacity: 1;
  }
.mainperformancediv{
    display: grid ;
grid-template-columns: repeat(1, minmax(0, 1fr)); 
gap: 1.5rem; 




}

.mainperformancedivpart2{
    display: grid ;
grid-template-columns: repeat(2, minmax(0, 1fr)); 
gap: 1rem; 







}

@media (min-width: 1024px) { 
    .mainperformancediv{
        display: grid ;
    grid-template-columns: repeat(3, minmax(0, 1fr)); 
    gap: 1.5rem; 
    }
    .mainperformancepartdiv{
        grid-column: span 2;

    }
   }

   

 
    .tooltip-contentthis:hover  {
        visibility: visible !important;
        opacity: 1 !important;
      }
      

    
     .tooltip-wrapper {
        position: relative;
        display: inline-block;
      }
      
      .tooltip-contentthis {
        position: absolute;
        bottom: 130%;
        right: 0; /* Align to the left */      
          visibility: hidden;
        opacity: 0;
        z-index: 50;
        padding: 0.5rem;
        border-radius: 0.5rem;
        font-size: 0.875rem;
        color: #ffffff;
        white-space: pre-line;
        background-color: #111827;
        transition: opacity 0.3s ease-in-out;
        width: 160px;
      }
      
      .tooltip-arrow {
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
        border-width: 5px;
        border-style: solid;
        border-color: #111827 transparent transparent transparent;
      }
      
      .info-icon:hover + .tooltip-contentthis {
        visibility: visible;
        opacity: 1;
      }
      
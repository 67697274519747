/* Privacy.module.css */

.privacyHeadingContainer {
    background-color: rgba(13, 150, 246, 0.6);
}

.privacyHeadingText {
    font-size: 36px;
    color: white;
}

.privacyContentContainer {
    background-color: rgba(13, 150, 246, 0.6);
}

.privacySectionTitle {
    font-size: 20px;
}

.privacyText {
    font-size: 12px;
}

.privacyList {
    font-size: 12px;
    color: white;
}
.textarea-count {
    border: none;
    box-shadow: 1px 5px 2px -2px #e5e5e5;
    align-items: center;
    background: #eeeeee 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 8px 8px;
    opacity: 1;
    min-height: calc(100vh - 265px);
    max-height: calc(100vh - 265px);
}

textarea {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: #fff;
    min-height: calc(100vh - 295px);
    max-height: calc(100vh - 295px);
    overflow: auto;
    border: none;
    border-radius: 4px;
    box-shadow: 1px 5px 2px -2px #e5e5e5;
}
.dashboard-card {
    background-color: "white";
    border-radius: 8px;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.login-card {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(10px);
    filter: brightness(115%);
    background-color: rgba(255, 255, 255, 0);
    padding: 40px 30px 100px 30px;
    min-width: 330;
}
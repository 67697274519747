.chartRow {
    padding: 0px;
}



.channelLabel {
    color: black;
    margin: 0;
}

.channelSelection {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.kpiRow {
    padding-top: 24px;
    padding-bottom: 0;
}

.chartRow {
    padding-top: 24px;
    padding-right: 3px;
    padding-left: 3px;
}

.chartRowTop {
    padding-top: 14px;
}

.chartColumn {
    padding: 10px;
    padding-top: 0;
    max-height: 350px;
}
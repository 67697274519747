.custom-top-nav {
    z-index: 2;
    position: fixed;
    top: 0;
    width: calc(100% - 250px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #fafafa;
}

.profile-icon-nav {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.collapsed-nav {
    width: 40px;
    height: 40px;
    border: none;
    background-color: white;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.collapsed-nav div {
    width: 25px;
    height: 3px;
    background-color: #1f9ad6;
    margin: 4px 0;
}

/* Mobile Menu Overlay */
.mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
}

.mobile-menu {
    width: 250px;
    height: 100%;
    background-color: rgb(31, 154, 214);
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    animation: slideIn 0.3s forwards;
    position: relative;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.close-button {
    position: absolute;
    top: 15px;
    right: 20px;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: white
}

.mobile-menu-links {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.mobile-menu-links a,
.mobile-menu-links .logout-link {
    display: flex;
    align-items: center;
    padding: 10px 0;
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}

.mobile-menu-links a img,
.mobile-menu-links .logout-link img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
}

.logout-link {
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
}

/* Existing Styles */
.custom-top-nav {
    z-index: 2;
    position: fixed;
    top: 0;
    width: calc(100% - 250px);
}

.profile-icon-nav {
    display: block;
}

.collapsed-nav {
    width: 40px;
    border: 0;
    background-color: white;
    display: none;
}

@media only screen and (max-width: 768px) {
    .side-menu {
        display: none;
    }

    .main-section {
        margin-left: 0px;
    }

    .custom-top-nav {
        width: 100%;
    }

    .profile-icon-nav {
        display: none;
    }

    .collapsed-nav {
        display: flex;
    }
}

.nav-mobile-list a {
    color: white
}
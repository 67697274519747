.bottom-holder {
    height: 55px;
}


.bottom-holder {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}




.bottom-holder span a {
    color: white;
    font-family: bm-bold;
    margin-left: 30px;
    margin-right: 30px;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
}
.filter-user-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 0px;
}

.id-filter-by-range {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0px;
}

.delete-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}


.action-div {
    display: flex;
    flex-direction: row;
}


.selected-users-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.idinput {
    width: 100%; /* Default width */
    margin: 10px 0;
    box-sizing: border-box;
  }

@media screen and (max-width: 912px) {
    .idinput,
    .idinput {
      width: 95%; /* Adjust the width to a smaller size */
    }
  }